
/**
 * Return the Nicotine average of an input array of Int
 * @param {object} series 
 * @returns 
 */
const getNicotineAverage = (series) => {
    let result = []

    if (series === null || series === undefined) {
        return 0
    }
    for (const [key, value] of Object.entries(series)) {
        value.data.forEach((element, i) => {
            result[i] ? result[i] += element : result[i] = element
        })
    }
    return Math.round(sum(result) / result.length)
}


const isTest = () => process.env.REACT_APP_FIREBASE_PROJECT_ID.toLocaleLowerCase().includes("test")
const bucketUrl = isTest() ? "https://kwit-test-eu-default-rtdb.europe-west1.firebasedatabase.app" : "https://kwit-prod-eu-default-rtdb.europe-west1.firebasedatabase.app"
/**
 * Return the sum of an int array
 * @param {array<int>} array 
 * @returns {int} the sum of the array value
 */
const sum = (array) => {
    var total = 0
    for (var i = 0; i < array.length; i++) total += array[i]
    return total
}

/**
 * Convert true false to "Yes"/"No" in french
 * @param {boolean} bool 
 * @returns {string}
 */
const boolToYesNo = (bool) => {
    return bool ? "Oui" : "Non"
}

export { getNicotineAverage, sum, boolToYesNo, bucketUrl }