import React, { useState, useCallback } from "react"
import { Dialog, DialogContent, DialogTitle, Skeleton, Tooltip, IconButton, Switch } from "@mui/material"
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useDashboard } from "../provider/DashboardProvider";
import CloseIcon from "@mui/icons-material/Close"

export const PrescriptionTips = () => {
    const { prescriptionData, outsideAmm, data } = useDashboard()
    const [isDialogOpened, setDialogOpened] = useState(false)
    const [checked, setChecked] = useState(false);
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    const handleChange = (event) => {
        setChecked(event.target.checked);
        forceUpdate();
    };

    const handleOpen = () => {
        setDialogOpened(true)
    }

    const handleClose = () => {
        setDialogOpened(false)
    }

    const renderRow = (data, id, isAmm) => {
        return (
            // if we are in the Amm case, the row have the amm checked state by default (at the page loading, the default checked state is equals to false)
            <tr hidden={isAmm ? !checked : false} key={id}>
                <td className="kwit-medium-text" style={{textAlign:"start"}}>{data.type}</td>
                <td className="kwit-green-text" style={{textAlign:"center"}}>{data.dosage}</td>
                <td className="kwit-green-text" style={{textAlign:"end"}}>{data.frequency}</td>
            </tr>
        )
    }

    return (
        <div className="card--medium card">
            <Dialog
                open={isDialogOpened}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>Recommandations<IconButton onClick={handleClose}><CloseIcon /></IconButton></DialogTitle>
                <DialogContent>
                    <p>
                        Ces recommandations sont élaborées à partir de l’équivalence :
                        <br />
                        1 cigarette = 1,3mg de nicotine
                        <br />
                        <br />
                        Cette équivalence a été croisée avec les différents RCP des substituts nicotiniques remboursés sur le marché français et selon les recommandations en vigueur afin de vous proposer un traitement de substitutions nicotiniques adapté. Cette recommandation permet de vous accompagner dans votre diagnostique mais peuvent nécessiter une adaptation par un professionnel de santé. Chaque patient a besoin d’une recommandation personnalisée en tenant compte de ses habitudes et ses besoins.
                        <br />
                        <br />
                        Cette équivalence n’est pas démontrée et est donnée à titre indicatif. Il est tout à fait possible de débuter avec un autre dosage. Il ne faut pas oublier que le nombre de cigarettes fumées ne définit pas le niveau de dépendance. Pour ajuster plus finement le dosage il est recommandé d’associer des formes orales aux patchs ou d’associer plusieurs patchs pour atteindre la dose journalière nécessaire, selon les recommandations de l’HAS.
                    </p>

                    <a href="https://www.has-sante.fr/upload/docs/application/pdf/2014-01/recommandations_-_arret_de_la_consommation_de_tabac.pdf">Source : Haute Autorité de santé (has-sante.fr)</a>
                </DialogContent>
            </Dialog>
            <h2 className="kwit-big-text">Conseil de prescription</h2>
            
            {
                prescriptionData ?
                    <div>
                        <table className="kwit-table">
                            <tbody>
                                <tr>
                                    <td className="kwit-medium-text">Nicotine par jour</td>
                                    <td className="kwit-green-text">{data.recommendedMg} mg</td>
                                </tr>
                            </tbody>    
                        </table>
                        <h3 className="kwit-medium-text">Recommandations <span className="vertical-align-middle"><Tooltip className="cursor-pointer vertical-align-middle" title="Cliquez-ici pour plus d'informations" placement="top" onClick={handleOpen}><InfoOutlinedIcon></InfoOutlinedIcon></Tooltip></span></h3>
                        <table className="kwit-table--bordered">
                            <tbody>
                                <tr>
                                    <td style={{textAlign:"start"}}>Forme</td>
                                    <td style={{textAlign:"center"}}>Dosage</td>
                                    <td style={{textAlign:"end"}}>Posologie</td>
                                </tr>
                                {
                                    prescriptionData.map((d, id) => renderRow(d, id, false))
                                }
                                {
                                    outsideAmm ? outsideAmm.map((d, id) => renderRow(d, id, true)) : null
                                }
                            </tbody>
                        </table>


                        {
                            outsideAmm ?
                                (<p className="kwit-small-text">
                                    <Switch
                                        checked={checked}
                                        onChange={handleChange}
                                    />
                                    J'autorise l'association de plusieurs patchs
                                </p>)
                                :
                                (<></>)

                        }

                    </div>
                    :
                    <div className="kwit-error-text">Aucune donnée à afficher</div>
            }
        </div>
    )
}