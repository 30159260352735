import React from "react"
import kwitLogo from "../images/kwit-logo.png"
import pierreFabreLogo from "../images/pierre-fabre-logo.png"
import { useDashboard } from "../provider/DashboardProvider"
import { Skeleton } from "@mui/material"

export const Header = () => {
    const { data } = useDashboard()
    return !data ? <></> : (
        <div className="header-wrapper">
            <div className="kwit-extra-large-text">{data.username}</div>
            <div className="row--vertical-centered" style={{justifyContent: "center"}}>
                <img alt="pierre-fabre-logo" src={pierreFabreLogo} />
                <img alt="kwit-logo" src={kwitLogo} />
            </div>
        </div>
    )
} 