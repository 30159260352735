import React from "react"
import gum from "../images/nrt-icons/gommes.png"
import lozenge from "../images/nrt-icons/pastilles.png"
import patch from "../images/nrt-icons/patchs.png"
import liquid from "../images/nrt-icons/flacon.png"
import pod from "../images/nrt-icons/pod.png"
import { useDashboard } from "../provider/DashboardProvider"
import 'swiper/scss'
import "swiper/scss/navigation"


const typeName = {
   lozenge: "Pastille",
   gum: "Gomme",
   patch: "Patch",
   liquid: "Liquide vape",
   pod: "Pod" 
}
const typeImage = {
   lozenge: lozenge,
   gum: gum,
   patch: patch,
   liquid: liquid,
   pod: pod
}
const unitSuffix = {
    mg: " mg",
    prct: "%",
    mgml: " mg/mL"
}
const Section = (configs /*UserFS.configs*/, configTypes /*Array<SubstituteType>*/, title /*String*/) => {
  const filtered = Object.entries(configs).filter(([id, conf]) => configTypes.includes(conf.type))

  return filtered.length == 0 ? <></> : (
    <div className="nrt__item">
        <span>{title}</span>
        {
            filtered.map(([id, config]) => 
                <div className="nrt__row" style={{textAlign:"end"}} key={id}>
                    <div className="row--align-items-center">
                        <img alt={config.type} src={typeImage[config.type]} className="nrt__icon"></img>
                        <p className="kwit-medium-text--bold">{config.name}</p>
                    </div>                                         
                    <p><span className="kwit-medium-text--bold">{config.dosage.toString().replace(".", ",")}{unitSuffix[config.dosageUnit]}</span>
                        { (config.type == "patch") && (<><br/><span className="kwit-medium-text">{config.duration.replace("PT", "").replace("H", "h")}</span></>) }
                    </p>
                </div>
            )
        }
    </div>  
  )
}

export const NrtData = () => {
    const { configs } = useDashboard()
    return (
        <div className="card--medium card">
            <h2 className="kwit-big-text">Apport en nicotine</h2>
            {              
                (Object.keys(configs ?? {}).length == 0) ? <div className="kwit-error-text">Aucune donnée à afficher</div> : (
                    <div>
                        { Section(configs, ["patch"], "Patchs") }
                        { Section(configs, ["lozenge", "gum"], "Pastilles / Gommes") }
                        { Section(configs, ["liquid", "pod"], "E-cigarette") }
                    </div>
                )
            }
        </div>
    )
}