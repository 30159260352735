import React, { useState } from "react"
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Tooltip, Dialog, DialogContent, IconButton, DialogTitle } from "@mui/material"
import { useDashboard } from "../provider/DashboardProvider";
import CloseIcon from '@mui/icons-material/Close';
import { getConfidenceImage, getConfidenceString, getFeelingColor, getFeelingString } from "../helpers/datatypes";
import { epochToDateString } from "../helpers/units";

export const PatientFollowUp = () => {
    const { checkinsData } = useDashboard()
    const [openedDialog1, setOpenedDialog1] = useState(false)

    /**
     * Dialog 1
     */
    const handleClickOpenDialog1 = () => {
        setOpenedDialog1(true)
    }

    const handleClickCloseDialog1 = () => {
        setOpenedDialog1(false)
    }

    return (
        <div className="card">
            <Dialog
                open={openedDialog1}
                onClose={handleClickCloseDialog1}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>Title<IconButton onClick={handleClickCloseDialog1}><CloseIcon /></IconButton></DialogTitle>
                <DialogContent>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas velit mi, lacinia sed mi quis, porta dapibus metus. Morbi nec massa sed quam tincidunt rutrum vel et lacus. Phasellus dictum a est sed molestie. Sed sed gravida felis. Maecenas eget tristique diam. Nam sapien ligula, finibus ac dolor non, finibus placerat nisi. Pellentesque consequat magna ultricies nulla imperdiet gravida. Integer aliquet nulla tellus, in molestie turpis blandit tempor. Nunc commodo justo cursus, mollis arcu ac, posuere neque.
                </DialogContent>
            </Dialog>
            <h2 className="kwit-big-text">Suivi patient</h2>
            <div className="green-bordered-div">
                <h3>Etat émotionnel et niveau de confiance {/* <span className="vertical-align-middle"><Tooltip className="cursor-pointer" title="Cliquez-ici pour plus d'informations"><InfoOutlinedIcon onClick={handleClickOpenDialog1}></InfoOutlinedIcon> </Tooltip></span> */}</h3>
                <p>7 derniers jours</p>
                    <div className="row--space-around">
                        {
                            checkinsData ?
                                checkinsData.map((object, id) => (renderCheckinDay(object, id))) :
                                <div className="kwit-error-text">Aucune donnée à afficher</div>
                        }
                    </div>
            </div>
        </div>
    )
}

const renderCheckinDay = (object, id) => {
    return (
        <div className="column--centered" key={id} >
            <div className="pill" style={{ backgroundColor: getFeelingColor(object.feeling) }}>{getFeelingString(object.feeling)} </div>
            <div className="column--centered">
                <img className="confidence-icon" src={getConfidenceImage(object.confidence)}></img>
                <p className="kwit-tiny-text--bold kwit-text-align--center">{getConfidenceString(object.confidence)}</p>
            </div>
            <p className="kwit-tiny-text">{epochToDateString(object.date, { month: 'numeric', day: 'numeric' })}</p>
        </div>
    )
}