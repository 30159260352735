import { sum } from "./utils"
import confident from "../images/feelings/confident.png"
import notConfident from "../images/feelings/notConfident.png"
import highlyConfident from "../images/feelings/highlyConfident.png"
import somewhatConfident from "../images/feelings/somewhatConfident.png"
import ratherNotConfident from "../images/feelings/rathernotconfident.png"

/**
 * Return the indexOf given string key, matching to "CigaretteDelay" values
 * @param {string} value 
 * @returns {int} correspond to a dependency Score
 * The score can be between 0 and 3
 */
const getCigaretteDelayPoints = (value) => {
    return ["moreThan1Hour", "between31And60Minutes", "between6And30Minutes", "upTo5Minutes"].indexOf(value)
}

/**
 * Compute a simplified fagerstrom score
 * @param {string} cigaretteDelay - this refers to cigaretteDelay values (an enum to the app)
 * @param {int} cigarettesPerDay - the current cigarette per day of the user
 * @returns {int} refers to the fagerstrom score, value can be between 0 and 6
 */
const getFagerstromScore = (cigaretteDelay, cigarettesPerDay) => {
    return getCigaretteDelayPoints(cigaretteDelay) + getCigarettesPerDayPoints(cigarettesPerDay)
}

/**
 * 
 * @param {string} cigarettesPerDay 
 * @returns 
 */
const getCigarettesPerDayPoints = (cigarettesPerDay) => {
    if (cigarettesPerDay <= 10) {
        return 0;
    } else if (cigarettesPerDay <= 20) {
        return 1;
    } else if (cigarettesPerDay <= 30) {
        return 2;
    } else {
        return 3;
    }
};

/**
 * Converts the gender int key to a displayable string value
 * @param {int} gender 
 * @returns {string} the gender string
 */
const getGenderString = (gender) => {
    let stringValues = {
        man: "Homme",
        woman: "Femme",
        other: "Autre",
        nonBinary: "Non binaire"
    };
    return stringValues[gender]
}

/**
 * Converts the fagerstrom score from int to displayable sentence
 * 0-1 -> "Pas de dépendance"
 * 2-3 -> "Dépendance modérée"
 * 4-6 -> "Dépendance forte"
 * @param {int} fagerstromScore
 * @returns {string} the display string for the given faggerstrom score
 */
const getStringDependency = (fagerstromScore) => {
    if (fagerstromScore <= 1) {
        return "Pas de dépendance"
    } else if (fagerstromScore > 1 && fagerstromScore <= 3) {
        return "Dépendance modérée"
    } else {
        return "Dépendance forte"
    }
}

/**
 * Daily checkin  : CONFIDENCE
 */
const confidenceValues = ["notConfident", "ratherNotConfident", "somewhatConfident", "confident", "highlyConfident"]

/**
 * getConfidenceString - returns a displayable string based on the confidence
 * @param {string} confidence - confidence key, see stringValues keys for more informations
 * @returns string
 * 
 * @example "notConfident" --> "Pas confiant(e)"
 *  */
const getConfidenceString = (confidence) => {
    let stringValues = {
        notConfident: "Pas confiant(e)",
        ratherNotConfident: "Plutôt pas confiant(e)",
        somewhatConfident: "Plutôt confiant(e)",
        confident: "Confiant(e)",
        highlyConfident: "Très confiant(e)"
    }
    return stringValues[confidence]
}

/**
 * 
 * @param {string} confidence - confidence key, see stringValues keys for more informations
 * @returns 
 */
const getConfidenceImage = (confidence) => {
    let stringValues = {
        notConfident: notConfident,
        ratherNotConfident: ratherNotConfident,
        somewhatConfident: somewhatConfident,
        confident: confident,
        highlyConfident: highlyConfident
    }
    return stringValues[confidence]
}

/**
 * todo : maybe remove it
 * @description return the confidence point to given confidence key
 * @param {string} confidence - see confidenceValues for available values
 * @returns {int} - The confidence point, used to calculate an average
 */
const getConfidencePoint = (confidence) => {
    return confidenceValues.indexOf(confidence)
}

/**
 * todo : maybe remove it
 * Compute the Confidence Average 
 * @param {array<Int>} confidenceArray 
 * @returns 
 */
const getConfidenceAverage = (confidenceArray) => {
    let average = Math.round(sum(confidenceArray) / confidenceArray.length)
    return getConfidenceString(confidenceValues[average])
}

/**
 * FEELINGS
 */
const feelingValues = {
    anger: { string: "Colère", color: "#FDC4C4" },
    disgust: { string: "Dégout", color: "#E4FBEE" },
    enjoyment: { string: "Joie", color: "#FFE7DB" },
    fear: { string: "Peur", color: "#E8DBF4" },
    sadness: { string: "Tristesse", color: "#E9EFFD" },
    calm: { string: "Calme", color: "#E9FFFC" },
    indifference: { string: "Indifférence", color: "#E4E9EE" }
}

/**
 * @param {string} feeling  - key of the feeling
 * @returns {string} the display string of the feeling, in french
 */
const getFeelingString = (feeling) => {
    return feelingValues[feeling].string
}

/**
 * @param {string} feeling - key of the feeling
 * @returns {string} the color code for the pill background color
 */
const getFeelingColor = (feeling) => {
    return feelingValues[feeling].color
}

export { getCigaretteDelayPoints, getFagerstromScore, getGenderString, getStringDependency, getConfidenceString, getFeelingString, feelingValues, getConfidencePoint, getConfidenceAverage, getFeelingColor, getConfidenceImage }