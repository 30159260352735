import React from "react"
import { Header } from "../components/Header"
import { PatientData } from "../components/PatientData"
import { NrtData } from "../components/NrtData"
import { PatientFollowUp } from "../components/PatientFollowUp"
import Grid from '@mui/material/Unstable_Grid2'
import { NicotineStats } from "../components/NicotineStats"
import { PrescriptionTips } from "../components/PrescriptionTips"
import { epochToDateString } from "../helpers/units"
import CircularProgress from '@mui/material/CircularProgress'; 
import { useDashboard } from "../provider/DashboardProvider";


export const DashboardView = () => {
    const { isLoaded } = useDashboard()
    return !isLoaded ? (<div style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}><CircularProgress ></CircularProgress></div>)
      :  (
        <Grid container spacing={3}>
        <Grid xs={12} sm={12}>
            <Header></Header>
        </Grid>
        <Grid xs={12} sm={12} md={4}>
            <PatientData></PatientData>
        </Grid>
        <Grid xs={12} sm={12} md={4}>
            <NrtData></NrtData>
        </Grid>
        <Grid xs={12} sm={12} md={4}>
            <PrescriptionTips/>
        </Grid>
        <Grid xs={12} sm={12} md={4}>
            <PatientFollowUp></PatientFollowUp>
        </Grid>
        <Grid xs={12} sm={12} md={8}>
            <NicotineStats></NicotineStats>
        </Grid>
        <Grid xs={12} sm={12} md={12}>
            <div className="responsive-row--centered-space-between">
                <p className="kwit-secondary-text">Données du {epochToDateString(Date.now(), { year: 'numeric', month: 'numeric', day: 'numeric' })}</p>
                <div className="row--align-items-center row--justify-content--space-around">
                    <p className="kwit-secondary-text std-margin--right">Plus d'informations sur :</p>
                    <a href="https://www.tabagora.com/" target="_blank" rel="noreferrer" className="std-margin--right">Tabagora</a>
                    <a href="https://discover.kwit.app/kwit-pharmacies/" target="_blank" rel="noreferrer" className="std-margin--right">Kwit</a>
                </div>
            </div>
        </Grid>
    </Grid>
    )
}