import React from "react"
import ReactApexChart from "react-apexcharts"
import { useDashboard } from "../provider/DashboardProvider"
import { getNicotineAverage } from "../helpers/utils"

export const NicotineStats = () => {
    const { data, nrtSeries, prescriptionData } = useDashboard()
    if (!data) return <></>
    else {
        let chartData = {
            options: {
                chart: {
                    type: 'bar',
                    stacked: true,
                    toolbar: {
                        show: false
                    },
                    zoom: {
                        enabled: false
                    }
                },
                annotations: {
                    yaxis: [
                        {
                            y: getNicotineAverage(nrtSeries),
                            borderColor: '#00E396',
                            label: {
                                position: 'left',
                                borderWidth: 0,
                                style: {
                                    color: '#00E396',
                                    fontWeight: 700,
                                    fontSize: 10
                                },
                                text: (getNicotineAverage(nrtSeries) ?? 0).toString()
                            }
                        }
                    ]
                },
                colors: ['#FFD0DF', '#BAEBE4', '#FDE48E', '#D9C5EC'],
                dataLabels: {
                    enabled: false
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '6px',
                    },
                },
                xaxis: {
                    type: 'datetime',
                    categories: data?.categories ?? [],
                    tickPlacement: 'on',
                    labels: {
                        datetimeFormatter: {
                            day: 'dd/MM',
                        },
                    }
                },
                yaxis: {
                    labels: {
                        formatter: function (value) {
                            return Math.round(value)
                        },
                        style: {
                            fontWeight: "bold"
                        }
                    }
                },
                legend: {
                    position: 'bottom'
                },
            },
        };

        return (
            <div className="card">
                <h2 className="kwit-big-text">Suivi nicotinique</h2>
                <div className="green-bordered-div">
                    <div className="row-space-between">
                        <div>
                            <h3>Prise de Nicotine</h3>
                            <p className="no-margin">28 derniers jours</p>
                        </div>
                            <div className="column--flex-end">
                                <p className="kwit-tiny-text--bold">Moyenne journalière</p>
                                <p className="kwit-medium-text--bold no-margin">
                                    {`${getNicotineAverage(nrtSeries)} mg`}</p>
                            </div>
                    </div>
                    {
                        nrtSeries ? <ReactApexChart options={chartData.options} series={nrtSeries} type="bar" height={350} />
                            : <div className="kwit-error-text">Aucune donnée à afficher</div>
                    
                    }
                </div>
            </div>
        )
    }
}