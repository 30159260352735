/**
 * Convert a given epoch ms to a readable string for user
 * @param {int} epoch - Given epoch ms
 * @param {object} options (optionnal) - the formatting options
 * @returns {string}
 */
const epochToDateString = (epoch, options) => {
    var d = new Date(0) // The 0 there is the key, which sets the date to the epoch
    const defaultOptions = { year: 'numeric', month: 'long', day: 'numeric' }
    d.setUTCMilliseconds(epoch)
    return options ? d.toLocaleDateString("fr-FR", options) : d.toLocaleDateString("fr-FR", defaultOptions)
}

export { epochToDateString }