import React from "react"
import { useDashboard } from "../provider/DashboardProvider"
import { getFagerstromScore, getGenderString, getStringDependency } from "../helpers/datatypes"
import { epochToDateString } from "../helpers/units"

export const PatientData = () => {
    const { data } = useDashboard()
    return (
        <div className="card">
            <h2 className="kwit-big-text">Données patient</h2>
            {data ?
                <table className="kwit-table">
                    <tbody>
                        <tr>
                            <td className="kwit-medium-text">Genre</td>
                            <td className="kwit-green-text"><span style={{verticalAlign:"bottom"}}>{getGenderString(data.gender) ?? "Non renseigné"}</span></td>
                        </tr>
                        <tr>
                            <td className="kwit-medium-text">Année de naissance</td>
                            <td className="kwit-green-text">{data.birthYear ?? "Non renseigné"}</td>
                        </tr>
                        <tr>

                            <td className="kwit-medium-text">Date d'arrêt</td>
                            <td className="kwit-green-text">{epochToDateString(data.quitDate, { year: 'numeric', month: 'long', day: 'numeric' })}</td>
                        </tr>
                        <tr>
                            <td className="kwit-medium-text">Cigarettes par jour avant l'arrêt</td>
                            <td className="kwit-green-text">{data.cigarettesPerDay}</td>
                        </tr>
                        <tr>
                            <td className="kwit-medium-text">Dépendance physique</td>
                            <td className="kwit-green-text" style={{textAlign:"end"}}>{getStringDependency(getFagerstromScore(data.firstCigaretteDelay, data.cigarettesPerDay))}</td>
                        </tr>
                        <tr>
                            <td className="kwit-medium-text">Test de Fagerström</td>
                            <td className="kwit-green-text">{getFagerstromScore(data.firstCigaretteDelay, data.cigarettesPerDay)}/6</td>
                        </tr>
                    </tbody>
                </table>
                : <></>
            }
        </div>
    )
}