import React, { useState, createContext, useContext, useEffect } from "react"
import axios from "axios"
import { useParams } from "react-router-dom"
import { bucketUrl } from "../helpers/utils"

const DashboardContext = createContext();

export const DashboardProvider = ({ children }) => {
    const [data, setData] = useState(null)
    const [configs, setConfigs] = useState(null)
    const [checkinsData, setCheckinsData] = useState(null)
    const [nrtSeries, setNrtSeries] = useState(null)
    const [prescriptionData, setPrescriptionData] = useState(null)
    const [outsideAmm, setOutsideAmm] = useState(null)
    const { id } = useParams()
    const [isLoaded, setIsLoaded] = useState(false)
    // This function is the core data treatment
    const getData = () => {
        axios.get(
            `${bucketUrl}/partners/hcp/users/${id}.json`
        ).then((res) => {
            const result = res.data.data
            //console.log(`DATA: ${JSON.stringify(result)}`)
            setData(result)
            setNrtSeries(result.series)
            setCheckinsData(result.dailyCheckins)
            setConfigs(result.configs)
            setPrescriptionData(result.prescription)
            setOutsideAmm(result.outsideAmm)
            setIsLoaded(true)
        }).catch(function (error) {
            setData(null)
            console.error("[#ERROR] An error occured : ")
            console.log(error)
        });
    }

    useEffect(() => {
        getData()
    }, []);

    return (
        <DashboardContext.Provider value={{ data, configs, checkinsData, nrtSeries, prescriptionData, outsideAmm, isLoaded }}>
            {children}
        </DashboardContext.Provider>
    )
}

export const useDashboard = () => useContext(DashboardContext)