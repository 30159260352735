import { DashboardProvider } from './provider/DashboardProvider'
import './scss/main.scss'
import { DashboardView } from './view/DashboardView'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import {Alert} from "@mui/material"

const App = () => {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/:id" element={<DashboardProvider><DashboardView></DashboardView></DashboardProvider>}/>
          <Route path="/" element={<Alert severity="error">Erreur : URL incorrecte</Alert>}>
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App
